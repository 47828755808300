<template>
	<div class="tourist-guide row justify-content-center pt-3 pt-lg-4">

		<!-- [Left] fixd logo  -->
		<transition name="fade">
			<div v-show="$route.name!='index'" class="page_kv_fixed">
				<img src="@/assets/images/img_kv_head_page.png" class="w-100" alt="">
			</div>
		</transition>

		<!-- 上方文字區塊 -->
		<div class="col-xl-8 text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
			<img v-if="deviceMode != 'mobile'" class="tourist-guide__title d-block mx-auto mb-3" src="@/assets/images/tourist/img_title.png" alt="精彩玩法路線圖">
			<img v-else class="tourist-guide__title mb-3" src="@/assets/images/tourist/img_title_s.png" alt="精彩玩法路線圖">
			<p>淡水是個好吃、好玩、好看又有趣的地方，景點不勝枚舉，想去淡水哪兒玩？<br>不論你是步行、自駕、騎單車，捷運、輕軌、坐渡輪，都不用擔心。<br>
			讓我們推薦八條精彩路線玩法，帶你看得開心、逛得盡興，玩出淡水獨有的風景！</p>
		</div>

		<div class="col-xl-10 mb-4 mb-md-3" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
			<router-link to="/files/淡水八景4.0手冊-電子版.pdf" target="_blank" class="d-block d-md-inline-block font-weight-bold btn btn-danger mb-2 mb-sm-0" role="button" aria-pressed="true">景點手冊下載</router-link>
		</div>

		<div class="col-xl-10" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
			<div class="tourist-guide__wrap">
				<transition name="fade">
					<inline-svg v-if="showGuide==''" :src="deviceMode != 'mobile' ? require('../assets/images/tourist/route__all.svg') : require('../assets/images/tourist/route__all_s.svg')" @loaded="svgLoaded()"/>
					<img v-else class="position-absolute tourist-guide__route" :src="deviceMode != 'mobile' ? require('../assets/images/tourist/route_'+showGuide+'.png') : require('../assets/images/tourist/route_'+showGuide+'_s.png')" alt="">
				</transition>
				<!-- back -->
				<router-link v-if="showGuide!=''" to="/tourist-guide" class="tourist-guide__back"><img src="@/assets/images/tourist/btn_back.png" class="w-100" ></router-link>
				<!-- zoom in -->
				<img v-if="showGuide!=''" src="@/assets/images/tourist/btn_zoomin.png" class="tourist-guide__zoomin" @click="showModal=true">
			</div>
		</div>

		<transition name="fade">
			<div v-if="showModal" v-bind:class="{ show: showModal }" class="modal" @click.self="showModal = false">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<button @click="showModal=false" type="button" class="close position-absolute m-0 p-0"></button>
					<img v-if="showGuide!='' && showModal" class="w-100" :src="deviceMode != 'mobile' ? require('../assets/images/tourist/route_'+showGuide+'.png') : require('../assets/images/tourist/route_'+showGuide+'_s.png')" alt="">
				</div>
			</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-if="showModal" :class="{ show: showModal }" class="modal__backdrop"></div>
		</transition>

	</div>	
</template>

<script>
import $ from 'jquery';
import InlineSvg from 'vue-inline-svg';

export default {
	components: {
		InlineSvg,
	},
	data() {
		return {
			showModal: false,
			showGuide:'',
			hoverRoute:'',
			clickRoute:'',
		}
	},
	created() {
		if(this.$route.name == 'tourist-guide' || this.$route.name > 8){
			this.showGuide = '';
		} else {
			this.showGuide = this.$route.name;
		}
	},
	updated() {
		if(this.$route.name == 'tourist-guide' || this.$route.name > 8){
			this.showGuide = '';
		} else {
			this.showGuide = this.$route.name;
		}
	},
	mounted() {
	},
	watch: {
		showModal() {
			this.showModal ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
		},
	},
	methods:{
		updatePath(nowPage, goPage) {
			this.$router.push(nowPage.path+'/'+goPage);
		},
		svgLoaded(){
			var tt = this;
			$("#route-1,#route-2,#route-3,#route-4,#route-5,#route-6,#route-7,#route-8").on( "mouseenter", function() {
				tt.hoverRoute=$(this).attr('id').split("-")[1];
				// console.log(tt.hoverRoute);
			}).on( "click", function() {
				tt.updatePath(tt.$route,tt.hoverRoute);
			});
		}
	}
}
</script>
